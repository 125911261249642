import React, {
  useState,
  useEffect,
  useRef,
  Fragment,
  useCallback,
} from "react";
import { DataGrid, GridPagination } from "@mui/x-data-grid";
import Button from "@mui/material/Button";
import Toolbar from "./homeToolbar";
import IconButton from "@mui/material/IconButton";
import ClearIcon from "@mui/icons-material/Clear";
import { red } from "@mui/material/colors";
import Tooltip from "@mui/material/Tooltip";
import Box from "@mui/material/Box";

import { styled } from "@mui/material/styles";
import { emptyCompany } from "../BoilerplateObjects";
import {
  mapCompanies,
  mapEmployees,
  mapTrainings,
} from "../../functions/HelperFunctions";
import { getMappedCompanies, getMappedCompany } from "../axios/Companies";
import { getCompanyTrainings } from "../axios/Trainings";
import { getCompanyEmployees } from "../axios/Employees";
import { getCompanyTrainingsReport } from "../axios/Reports";
import { getCourses } from "../axios/Courses";
import { getInstructors } from "../axios/Users";
import { saveRemindersForCompany } from "../axios/Reminders";
import {
  handleDetailsClick,
  handleChange,
  handleCancelOrSave,
  countDrivers,
} from "../../functions/HelperFunctions";
import EmailCompanyTrainingsModal from "../modals/EmailCompanyTrainingsModal";

// Session Imports
import { isAdmin, companyName } from "../axios/UserSession";

const NewTable = styled(DataGrid)({
  fontFamily: "Poppins",
  textTransform: "none",
  backgroundColor: "white",
});

const TableButton = styled(Button)({
  fontFamily: "Poppins",
  color: "white",
  margin: "10px",
  textTransform: "none",
  width: "110px",
  ":hover": {
    opacity: ".8",
    backgroundColor: "#21357B",
  },
});

const EmailButton = styled(Button)({
  fontFamily: "Poppins",
  color: "#21357B",
  margin: "10px",
  textTransform: "none",
  ":hover": {
    opacity: ".8",
    color: "#21357B",
    backgroundColor: "#FFFFFF",
    borderColor: "#21357B",
  },
});

const selected = {
  color: "#FFFFFF",
  backgroundColor: "#21357B",
  borderColor: "#FFFFFF",
};

const unselected = {
  color: "#21357B",
  backgroundColor: "#FFFFFF",
  borderColor: "#21357B",
};

let allInstructors = [];

const authenticatedUserCompany = companyName;

function Table({
  handleAddButtonOpen,
  handleDeleteButtonOpen,
  setCompanyId,
  setCompanyName,
  setAllCompanies,
  selectedValue,
  handleAlertSuccess,
  setAsyncRefresh,
}) {
  const listColumns = [
    {
      field: "companyName",
      headerName: "Business Name",
      minWidth: 300,
      sortable: true,
    },
    {
      field: "isHidden",
      headerName: "Status",
      minWidth: 120,
      sortable: true,
      renderCell: (params) => {
        return (
          <div>
            {params.value === "false" || params.value === false
              ? "Active"
              : "Deleted"}
          </div>
        );
      },
    },
    {
      field: "address",
      headerName: "Address",
      minWidth: 200,
      sortable: true,
    },
    {
      field: "city",
      headerName: "City",
      minWidth: 120,
      sortable: true,
    },
    {
      field: "state",
      headerName: "State",
      minWidth: 100,
      sortable: true,
    },
    { field: "zip", headerName: "Zip", minwidth: 100, sortable: false },
    {
      field: "phone",
      headerName: "Phone",
      minWidth: 200,
      sortable: true,
    },
    {
      field: "email",
      headerName: "Email",
      minWidth: 250,
      sortable: true,
    },
    {
      field: "delete",
      headerName: "",
      minWidth: 60,
      sortable: true,
      renderCell: (params) => {
        return (
          <Tooltip title="Delete">
            <IconButton
              disabled={!isAdmin}
              sx={{ color: red[500] }}
              onClick={() => {
                handleDeleteButtonOpen("Company", params);
              }}
            >
              <ClearIcon />
            </IconButton>
          </Tooltip>
        );
      },
    },
  ];

  const employeeColumns = [
    {
      field: "id",
      headerName: "ID",
      minWidth: 200,
      sortable: false,
      renderCell: (params) => {
        return <div>{params.value.slice(-6).toUpperCase()}</div>;
      },
    },
    {
      field: "lastName",
      headerName: "Last Name",
      minWidth: 250,
      sortable: true,
    },
    {
      field: "firstName",
      headerName: "First Name",
      minWidth: 250,
      sortable: true,
    },
    {
      field: "state",
      headerName: "State",
      minWidth: 100,
      sortable: true,
    },
    {
      field: "consortium",
      headerName: "Consortium",
      minWidth: 120,
      sortable: true,
    },
    {
      field: "isDriver",
      headerName: "Driver",
      minwidth: 120,
      sortable: false,
      renderCell: (params) => {
        return <div>{params.value && "D"}</div>;
      },
    },
    {
      field: "isHidden",
      headerName: "Status",
      minWidth: 120,
      sortable: true,
      renderCell: (params) => {
        return <div>{params.value ? "Deleted" : "Active"}</div>;
      },
    },
    {
      field: "delete",
      headerName: "",
      minWidth: 100,
      sortable: true,
      renderCell: (params) => {
        return (
          <Tooltip title="Delete">
            <IconButton
              disabled={!isAdmin}
              sx={{ color: red[500] }}
              onClick={() => {
                handleDeleteButtonOpen("Employee", params);
              }}
            >
              <ClearIcon />
            </IconButton>
          </Tooltip>
        );
      },
    },
  ];

  const trainingColumns = [
    {
      field: "title",
      headerName: "Title",
      minWidth: 250,
      sortable: true,
    },
    {
      field: "date",
      headerName: "Training Date",
      minWidth: 200,
      sortable: true,
    },
    {
      field: "instructor",
      headerName: "Instructor",
      minWidth: 200,
      sortable: true,
    },
    {
      field: "isHidden",
      headerName: "Status",
      minWidth: 150,
      sortable: true,
      renderCell: (params) => {
        return <div>{params.value ? "Deleted" : "Active"}</div>;
      },
    },
    {
      field: "delete",
      headerName: "Delete",
      minWidth: 100,
      sortable: true,
      renderCell: (params) => {
        return (
          <Tooltip title="Delete">
            <IconButton
              disabled={!isAdmin}
              sx={{ color: red[500] }}
              onClick={() => {
                handleDeleteButtonOpen("Training", params);
              }}
            >
              <ClearIcon />
            </IconButton>
          </Tooltip>
        );
      },
    },
  ];

  const [data, setData] = useState([]);
  const [trainings, setTrainings] = useState([]);
  const [allCompanyEmployees, setAllCompanyEmployees] = useState([]);
  const [attendees, setAttendees] = useState([]);
  const [currentTab, setCurrentTab] = useState("Company");
  const [actionCategory, setActionCategory] = useState("Company");
  const [footerDisplayed, setFooterDisplayed] = useState("Companies");
  const [rowClicked, setRowClicked] = useState({});
  const [companyReminders, setCompanyReminders] = useState([]);
  const [columns, setColumns] = useState(listColumns);
  const [rows, setRows] = useState([]);
  const [checked, setChecked] = useState({
    alcoholAndDrug: false,
    training: false,
    reminders: false,
    isDriver: false,
    clearinghouse: false,
  });
  const [selectedCourses, setSelectedCourses] = useState([]);
  const [allCourses, setAllCourses] = useState([]);
  const values = useRef(emptyCompany);

  // Company Details & Add New Company Tooltips opened/closed
  const [detailsOpen, isDetailsOpen] = useState(false);
  const [newOpen, isNewOpen] = useState(false);

  // Company Reminders Email Modal State
  const [emailCompanyTrainingsModal, setEmailCompanyTrainingsModal] =
    useState(false);

  const [sortModel, setSortModel] = useState([
    { field: "companyName", sort: "asc" },
  ]);

  const handleClose = () => {
    setEmailCompanyTrainingsModal(false);
  };

  const tabClicked = (tab) => {
    // Update state based on tab
    setCurrentTab(tab);

    // Update sort model based on tab
    if (tab === "Company") {
      setSortModel([{ field: "companyName", sort: "asc" }]);
      setColumns(listColumns);
      mapCompanies(data, setRows);
      setFooterDisplayed("Companies");
    } else if (tab === "employees") {
      setSortModel([{ field: "lastName", sort: "asc" }]);
      setColumns(employeeColumns);
      fetchAndSaveCompanyEmployees(rowClicked.companyId);
      setFooterDisplayed("Employees");
    } else if (tab === "training" && rowClicked.id !== undefined) {
      setSortModel([{ field: "date", sort: "desc" }]);
      fetchEmployeesForTrainings(rowClicked.companyId);
      getCompanyTrainings({
        id: rowClicked.id,
        companyId: rowClicked.companyId,
        setTrainings,
        mapTrainings,
        setRows,
      });
      setColumns(trainingColumns);
      setFooterDisplayed("Trainings");
    }
  };

  useEffect(() => {
    if (currentTab === "employees") {
      setActionCategory("Employee");
    } else if (currentTab === "Company") {
      setActionCategory("Company");
    } else if (currentTab === "training") {
      setActionCategory("Training");
    }
  }, [currentTab]);

  const refreshData = useCallback(async () => {
    if (actionCategory === "Company") {
      if (isAdmin) {
        getMappedCompanies({ setData, mapCompanies, setRows });
      } else {
        getMappedCompany(authenticatedUserCompany, {
          setData,
          mapCompanies,
          setRows,
        });
      }
      if (rowClicked.companyId !== undefined) {
        await fetchCompanyReminders(rowClicked.companyId);
      }
    } else if (
      actionCategory === "Employee" &&
      rowClicked.companyId !== undefined
    ) {
      const empResponse = await fetchAndSaveCompanyEmployees(
        rowClicked.companyId
      );
      if (empResponse) {
        // Avoid switching back and forth between tabs.
        tabClicked("employees");
      }
    } else if (
      actionCategory === "Training" &&
      rowClicked.companyId !== undefined
    ) {
      const coursesResponse = await fetchCoursesAndSave();
      const instructorsResponse = await fetchInstructorsAndSave();
      const employeesResponse = await fetchEmployeesForTrainings(
        rowClicked.companyId
      );
      if (coursesResponse && instructorsResponse && employeesResponse) {
        // Avoid switching back and forth between tabs.
        tabClicked("training");
      }
    }
  }, [currentTab, actionCategory, rowClicked.companyId]);

  const updateTableForSearch = () => {
    if (selectedValue && typeof selectedValue === "object") {
      // Check if selectedValue is an object (selected from dropdown)
      const filteredCompanies = data.filter(
        (company) => company.companyName === selectedValue.companyName
      );
      mapCompanies(filteredCompanies, setRows); // Map only the selected company
    } else if (typeof selectedValue === "string" && selectedValue !== "") {
      // If the user types in a value, filter by that value
      const filteredCompanies = data.filter((company) =>
        company.companyName.toLowerCase().includes(selectedValue.toLowerCase())
      );
      mapCompanies(filteredCompanies, setRows);
    } else {
      // Show all companies if no input or selection
      mapCompanies(data, setRows);
    }
  };

  // Data fetching useEffect, runs only on mount
  useEffect(() => {
    if (isAdmin) {
      getMappedCompanies({ setData, mapCompanies, setRows });
    } else {
      getMappedCompany(authenticatedUserCompany, {
        setData,
        mapCompanies,
        setRows,
      });
    }
  }, []); // Empty dependency array ensures this runs only once on mount

  // useEffect to update setAsyncRefresh when refreshData changes
  useEffect(() => {
    setAsyncRefresh(() => refreshData);
  }, [refreshData]); // Only updates setAsyncRefresh, doesn't fetch data

  useEffect(() => {
    if (selectedValue === null) {
      if (currentTab === "Company") {
        setAllCompanies(data);
        mapCompanies(data, setRows);
      }
    } else {
      tabClicked("Company");
      updateTableForSearch();
    }
  }, [data, selectedValue]);

  useEffect(() => {
    fetchCoursesAndSave();
    fetchInstructorsAndSave();
  }, []);

  const fetchAndSaveCompanyEmployees = async (companyId) => {
    try {
      const companyEmployees = await getCompanyEmployees(companyId);

      // Sort employees alphabetically by last name
      const sortedEmployees = companyEmployees.sort((a, b) =>
        a.lastName.localeCompare(b.lastName)
      );

      mapEmployees(sortedEmployees, setRows);
      return true;
    } catch (error) {
      console.error("Couldn't fetch and save employees!", error.message);
    }
  };

  const fetchEmployeesForTrainings = async (companyId) => {
    try {
      const companyEmployees = await getCompanyEmployees(companyId);
      setAllCompanyEmployees(companyEmployees);
      return true;
    } catch (error) {
      console.error("Couldn't fetch and save employees!", error.message);
    }
  };

  const fetchCoursesAndSave = async () => {
    try {
      const courses = await getCourses();
      setAllCourses(courses);
      return true;
    } catch (error) {
      console.error(error);
    }
  };

  const fetchInstructorsAndSave = async () => {
    try {
      const instructors = await getInstructors();
      allInstructors = instructors;
      return true;
    } catch (error) {
      console.error(error);
    }
  };

  const fetchCompanyReminders = async (data) => {
    try {
      const reminders = await saveRemindersForCompany({
        companyId: data,
      });
      setCompanyReminders(reminders);
    } catch (error) {
      console.error(error);
    }
  };

  const handleRowClick = (params) => {
    setRowClicked(Object.assign(rowClicked, params.row));
    setCompanyId(params.row.id);
    setCompanyName(params.row.companyName);
    if (actionCategory === "Training") {
      setSelectedCourses(params.row.courses);
      setAttendees(params.row.attendees);
    } else if (actionCategory === "Company") {
      fetchCompanyReminders(params.row.id);
    }
  };

  const updateAttendees = (newAttendees) => {
    setAttendees(newAttendees); // Update the local state
  };

  const handleDownloadReport = () => {
    function mapTrainingData(data) {
      const uniqueAttendees = {};

      data.forEach((record) => {
        const { courses, trainingDate } = record;

        record.attendees.forEach((attendee) => {
          const { firstName, lastName, driverId } = attendee;

          if (!uniqueAttendees[driverId]) {
            uniqueAttendees[driverId] = {
              firstName,
              lastName,
              driverId,
              courses: [...courses],
              trainingDate,
            };
          }
        });
      });

      return Object.values(uniqueAttendees);
    }

    const reportData = mapTrainingData(trainings);
    getCompanyTrainingsReport(reportData);
  };

  return (
    <div style={{ height: "100%", width: "100%" }}>
      <div style={{ height: "100%", width: "100%" }}>
        <NewTable
          disableRowSelectionOnClick
          rows={rows}
          columns={columns}
          onRowClick={handleRowClick}
          onRowDoubleClick={handleDetailsClick({
            isNewOpen,
            isDetailsOpen,
            actionCategory,
            rowClicked,
            setChecked,
            values,
          })}
          sortModel={sortModel}
          onSortModelChange={(model) => setSortModel(model)}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 100 },
            },
          }}
          pageSizeOptions={[25, 50, 100]}
          slots={{
            toolbar: Toolbar,
            footer: () => (
              <>
                <div
                  style={{
                    display: "flex",
                    direction: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <Box
                    sx={{
                      p: currentTab === "training" ? 0 : 2,
                      fontWeight: "bold",
                      display: "flex",
                      direction: "row",
                      alignItems: "center",
                    }}
                  >
                    {currentTab === "training" && (
                      <Fragment>
                        <div>
                          <TableButton
                            disabled={
                              trainings.length && isAdmin ? false : true
                            }
                            sx={trainings.length ? selected : unselected}
                            onClick={handleDownloadReport}
                          >
                            Show Report
                          </TableButton>
                        </div>
                        <div style={{ marginRight: "10px" }}>
                          <EmailButton
                            disabled={
                              trainings.length && isAdmin ? false : true
                            }
                            onClick={() => setEmailCompanyTrainingsModal(true)}
                            variant="outlined"
                          >
                            Email
                          </EmailButton>
                        </div>
                      </Fragment>
                    )}
                    <div
                      style={{
                        display: "flex",
                        direction: "row",
                      }}
                    >
                      <div style={{ marginRight: "20px" }}>
                        Total {footerDisplayed}: {rows.length}
                      </div>
                      {currentTab === "employees" && (
                        <div>Total Drivers: {countDrivers(rows)}</div>
                      )}
                    </div>
                  </Box>
                  <GridPagination />
                </div>
              </>
            ),
          }}
          slotProps={{
            toolbar: {
              attendees,
              tabClicked,
              checked,
              setChecked,
              tab: currentTab,
              actionCategory,
              rowClicked,
              allCompanyEmployees,
              updateAttendees,
              handleDetailsClick,
              isDetailsOpen,
              isNewOpen,
              handleChange,
              handleCancelOrSave,
              handleAlertSuccess,
              detailsOpen,
              values,
              newOpen,
              printOptions: {
                hideFooter: true,
                hideToolbar: true,
              },
              handleAddButtonOpen,
              setSelectedCourses,
              selectedCourses,
              allCourses,
              setAllCourses,
              allInstructors,
              companyReminders,
            },
          }}
        />
        <EmailCompanyTrainingsModal
          isOpen={emailCompanyTrainingsModal}
          handleClose={handleClose}
          handleDownloadReport={handleDownloadReport}
          rowClicked={rowClicked}
        />
      </div>
    </div>
  );
}

export default Table;
