import React from "react";
import { DataGrid, GridPagination } from "@mui/x-data-grid";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";

import ScreeningsToolbar from "./screeningsToolbar";

const NewTable = styled(DataGrid)({
  fontFamily: "Poppins",
  textTransform: "none",
  backgroundColor: "white",
});

function ScreeningsTable({
  columns,
  rows,
  tabClicked,
  tab,
  allDrivers,
  allEmployees,
  handleRowClick,
  setAddInfrequentScreeningsModal,
  isLoading,
  asyncRefresh,
  selectionMade,
}) {
  if (isLoading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          width: "100%",
        }}
      >
        <CircularProgress size={60} />
      </Box>
    );
  }

  // Determine initial sort model based on view type
  const getInitialSortModel = () => {
    if (
      tab === "Infrequent" ||
      selectionMade === "Companies" ||
      selectionMade === "Employees"
    ) {
      return [{ field: "companyName", sort: "asc" }];
    }
    return [];
  };

  return (
    <div style={{ height: "100%", width: "100%" }}>
      <div style={{ height: "100%", width: "100%" }}>
        <NewTable
          disableRowSelectionOnClick
          onRowClick={handleRowClick}
          rows={rows}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 100 },
            },
            sorting: {
              sortModel: getInitialSortModel(),
            },
          }}
          pageSizeOptions={[25, 50, 100]}
          slots={{
            toolbar: ScreeningsToolbar,
            footer: () => (
              <>
                <div style={{ display: "flex", direction: "row" }}>
                  <div
                    style={{ width: "100%", display: "flex", direction: "row" }}
                  >
                    <Box
                      sx={{
                        p: 2,
                        fontWeight: "bold",
                      }}
                    >
                      Total Rows: {rows.length}
                    </Box>
                    <Box
                      sx={{
                        p: 2,
                        fontWeight: "bold",
                      }}
                    >
                      Total Employees: {allEmployees}
                    </Box>
                    <Box
                      sx={{
                        p: 2,
                        fontWeight: "bold",
                      }}
                    >
                      Total Drivers: {allDrivers}
                    </Box>
                  </div>
                  <GridPagination />
                </div>
              </>
            ),
          }}
          slotProps={{
            toolbar: {
              tab,
              tabClicked,
              printOptions: {
                hideFooter: true,
                hideToolbar: true,
              },
              setAddInfrequentScreeningsModal,
              asyncRefresh,
            },
          }}
        />
      </div>
    </div>
  );
}

export default ScreeningsTable;
